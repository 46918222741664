.App__container___po1AR {
  position: relative;
  overflow: hidden;
  font-size: 14px
}
.App__container___po1AR .App__header___-ZQdH {
  height: 80px
}
.App__container___po1AR .App__header___-ZQdH .App__headerLogo___2RRo_ {
  height: 50px;
  padding-top: 16px;
}
.App__container___po1AR .App__headerLogoWrapper___1zSo6 {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.App__container___po1AR .App__header_amplifon___2SqJP {
  background: #c5003e;
  background: -webkit-gradient(left top,right top,color-stop(0%,#c5003e),color-stop(100%,#560f50));
  background: _linear-gradient_legacy(to right,#c5003e 0,#560f50,100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c5003e',endColorstr='#560f50',GradientType=1);
}
.App__container___po1AR .App__intro___2fPGC {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center
}
.App__container___po1AR .App__intro___2fPGC .App__inner___15tV1 {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  color: white
}
.App__container___po1AR .App__intro___2fPGC .App__inner___15tV1 .App__title___rsy3t {
  font-size: 45px;
  font-weight: 300;
  padding: 10px 20px;
}
@media (max-width: 768px) {
  .App__container___po1AR .App__intro___2fPGC .App__inner___15tV1 .App__title___rsy3t {
    font-size: 30px
  }
}
.App__container___po1AR .App__toggles___2Kpn4 {
  width: 100%;
  min-height: 202px;
  background: #f7f6f4;
}
@media (max-width: 768px) {
  .App__container___po1AR .App__toggles___2Kpn4 {
    padding: 20px
  }
}
.App__container___po1AR .App__toggles___2Kpn4 .App__inner___15tV1 {
  max-width: 1320px;
  margin: 0 auto
}
.App__container___po1AR .App__toggles___2Kpn4 .App__inner___15tV1:before,
      .App__container___po1AR .App__toggles___2Kpn4 .App__inner___15tV1:after {
  content: " ";
  display: table;
}
.App__container___po1AR .App__toggles___2Kpn4 .App__inner___15tV1:after {
  clear: both;
}
.App__container___po1AR .App__toggles___2Kpn4 .App__box_width_33___1su4h {
  width: 33%;
}
.App__container___po1AR .App__toggles___2Kpn4 .App__box_width_25___3_3J4 {
  width: 25%;
}
.App__container___po1AR .App__toggles___2Kpn4 .App__products___1JfI6,
    .App__container___po1AR .App__toggles___2Kpn4 .App__styles___CyZjb,
    .App__container___po1AR .App__toggles___2Kpn4 .App__media____AHJy,
    .App__container___po1AR .App__toggles___2Kpn4 .App__types___1-Lrp {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  float: left;
}
@media (max-width: 768px) {
  .App__container___po1AR .App__toggles___2Kpn4 .App__products___1JfI6,
    .App__container___po1AR .App__toggles___2Kpn4 .App__styles___CyZjb,
    .App__container___po1AR .App__toggles___2Kpn4 .App__media____AHJy,
    .App__container___po1AR .App__toggles___2Kpn4 .App__types___1-Lrp {
    width: 100%
  }
}
.App__container___po1AR .App__toggles___2Kpn4 .App__products___1JfI6 .App__items_wrapper___26VRF, .App__container___po1AR .App__toggles___2Kpn4 .App__styles___CyZjb .App__items_wrapper___26VRF, .App__container___po1AR .App__toggles___2Kpn4 .App__media____AHJy .App__items_wrapper___26VRF, .App__container___po1AR .App__toggles___2Kpn4 .App__types___1-Lrp .App__items_wrapper___26VRF {
  display: block;
  width: 300px;
  margin: 0 auto;
  padding: 50px 25px;
}
@media (max-width: 768px) {
  .App__container___po1AR .App__toggles___2Kpn4 .App__products___1JfI6 .App__items_wrapper___26VRF, .App__container___po1AR .App__toggles___2Kpn4 .App__styles___CyZjb .App__items_wrapper___26VRF, .App__container___po1AR .App__toggles___2Kpn4 .App__media____AHJy .App__items_wrapper___26VRF, .App__container___po1AR .App__toggles___2Kpn4 .App__types___1-Lrp .App__items_wrapper___26VRF {
    padding: 5px;
    margin: 0
  }
}
.App__container___po1AR .App__toggles___2Kpn4 .App__products___1JfI6 .App__title___rsy3t, .App__container___po1AR .App__toggles___2Kpn4 .App__styles___CyZjb .App__title___rsy3t, .App__container___po1AR .App__toggles___2Kpn4 .App__media____AHJy .App__title___rsy3t, .App__container___po1AR .App__toggles___2Kpn4 .App__types___1-Lrp .App__title___rsy3t {
  color: #3b3c3d;
  font-weight: 700;
}
.App__container___po1AR .App__toggles___2Kpn4 .App__products___1JfI6 .App__items___AWHjv, .App__container___po1AR .App__toggles___2Kpn4 .App__styles___CyZjb .App__items___AWHjv, .App__container___po1AR .App__toggles___2Kpn4 .App__media____AHJy .App__items___AWHjv, .App__container___po1AR .App__toggles___2Kpn4 .App__types___1-Lrp .App__items___AWHjv {
  font-weight: 300;
  margin-top: 12px
}
.App__container___po1AR .App__toggles___2Kpn4 .App__products___1JfI6 .App__items___AWHjv .App__item___1UNBb, .App__container___po1AR .App__toggles___2Kpn4 .App__styles___CyZjb .App__items___AWHjv .App__item___1UNBb, .App__container___po1AR .App__toggles___2Kpn4 .App__media____AHJy .App__items___AWHjv .App__item___1UNBb, .App__container___po1AR .App__toggles___2Kpn4 .App__types___1-Lrp .App__items___AWHjv .App__item___1UNBb {
  display: block;
  cursor: pointer;
  position: relative
}
.App__container___po1AR .App__toggles___2Kpn4 .App__products___1JfI6 .App__items___AWHjv .App__item___1UNBb .App__label___3g_IC, .App__container___po1AR .App__toggles___2Kpn4 .App__styles___CyZjb .App__items___AWHjv .App__item___1UNBb .App__label___3g_IC, .App__container___po1AR .App__toggles___2Kpn4 .App__media____AHJy .App__items___AWHjv .App__item___1UNBb .App__label___3g_IC, .App__container___po1AR .App__toggles___2Kpn4 .App__types___1-Lrp .App__items___AWHjv .App__item___1UNBb .App__label___3g_IC {
  position: absolute;
  left: 30px;
  top: 3px;
  font-size: 12px;
}
.App__container___po1AR .App__toggles___2Kpn4 .App__products___1JfI6 .App__items___AWHjv .App__item___1UNBb .App__checkbox___29vxW, .App__container___po1AR .App__toggles___2Kpn4 .App__styles___CyZjb .App__items___AWHjv .App__item___1UNBb .App__checkbox___29vxW, .App__container___po1AR .App__toggles___2Kpn4 .App__media____AHJy .App__items___AWHjv .App__item___1UNBb .App__checkbox___29vxW, .App__container___po1AR .App__toggles___2Kpn4 .App__types___1-Lrp .App__items___AWHjv .App__item___1UNBb .App__checkbox___29vxW {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCI+ICA8ZGVmcz4gICAgPHN0eWxlPiAgICAgIC5jbHMtMSB7ICAgICAgICBmaWxsOiAjZmZmOyAgICAgICAgc3Ryb2tlOiAjY2JjOWM0OyAgICAgIH0gICAgICAuY2xzLTIgeyAgICAgICAgc3Ryb2tlOiBub25lOyAgICAgIH0gICAgICAuY2xzLTMgeyAgICAgICAgZmlsbDogbm9uZTsgICAgICB9ICAgIDwvc3R5bGU+ICA8L2RlZnM+ICA8ZyBpZD0iUmVjdGFuZ2xlXzg3IiBkYXRhLW5hbWU9IlJlY3RhbmdsZSA4NyIgY2xhc3M9ImNscy0xIj4gICAgPHJlY3QgY2xhc3M9ImNscy0yIiB3aWR0aD0iMzAiIGhlaWdodD0iMzAiLz4gICAgPHJlY3QgY2xhc3M9ImNscy0zIiB4PSIwLjUiIHk9IjAuNSIgd2lkdGg9IjI5IiBoZWlnaHQ9IjI5Ii8+ICA8L2c+PC9zdmc+);
  background-size: contain;
  background-repeat: no-repeat;
}
.App__container___po1AR .App__toggles___2Kpn4 .App__products___1JfI6 .App__items___AWHjv .App__item___1UNBb .App__checkbox_checked___3lXCV, .App__container___po1AR .App__toggles___2Kpn4 .App__styles___CyZjb .App__items___AWHjv .App__item___1UNBb .App__checkbox_checked___3lXCV, .App__container___po1AR .App__toggles___2Kpn4 .App__media____AHJy .App__items___AWHjv .App__item___1UNBb .App__checkbox_checked___3lXCV, .App__container___po1AR .App__toggles___2Kpn4 .App__types___1-Lrp .App__items___AWHjv .App__item___1UNBb .App__checkbox_checked___3lXCV {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzAgMzAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMwIDMwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6I0ZGRkZGRjt9LnN0MXtmaWxsOm5vbmU7c3Ryb2tlOiNDQkM5QzQ7fS5zdDJ7ZmlsbDojQzYxNjhEO308L3N0eWxlPjxnIGlkPSJSZWN0YW5nbGVfMzUiPjxyZWN0IGNsYXNzPSJzdDAiIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIvPjxyZWN0IHg9IjAuNSIgeT0iMC41IiBjbGFzcz0ic3QxIiB3aWR0aD0iMjkiIGhlaWdodD0iMjkiLz48L2c+PGcgaWQ9Il94MzJfNC1pbnRlcmZhY2VfLV90aWNrX2RvbmVfMl8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0wLjEgLTAuMDc1KSI+PGcgaWQ9Ikdyb3VwXzEzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjEgMC4wNzUpIj48cGF0aCBpZD0iUGF0aF8xNSIgY2xhc3M9InN0MiIgZD0iTTIzLDljLTAuNC0wLjQtMS0wLjQtMS40LDBsLTkuNyw5LjdsLTMuNC0zLjRjLTAuNC0wLjQtMS4xLTAuNC0xLjUsMGMtMC40LDAuNC0wLjQsMS4xLDAsMS41bDQuMSw0LjFoMC4xVjIxYzAuNCwwLjQsMSwwLjQsMS40LDBMMjMsMTAuNUMyMy40LDEwLjEsMjMuNCw5LjQsMjMsOXoiLz48L2c+PC9nPjwvc3ZnPg==);
  background-size: contain;
  background-repeat: no-repeat;
}
.App__container___po1AR .App__toggles___2Kpn4 .App__products___1JfI6 .App__items___AWHjv input[type="checkbox"], .App__container___po1AR .App__toggles___2Kpn4 .App__styles___CyZjb .App__items___AWHjv input[type="checkbox"], .App__container___po1AR .App__toggles___2Kpn4 .App__media____AHJy .App__items___AWHjv input[type="checkbox"], .App__container___po1AR .App__toggles___2Kpn4 .App__types___1-Lrp .App__items___AWHjv input[type="checkbox"] {
  display: none;
}
.App__container___po1AR .App__list___1Y_Ke {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy {
  padding-bottom: 25px
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaName___3djyN {
  color: #3b3c3d;
  font-weight: 700;
  padding: 8px 0;
  line-height: 30px;
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaClear___2JdX2 {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  background: #7e7b78;
  height: 20px;
  border-radius: 50%
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaClear___2JdX2:before,
        .App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaClear___2JdX2:after {
  content: " ";
  display: table;
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaClear___2JdX2:after {
  clear: both;
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaAmount___2k4ez {
  color: #fff;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
  min-width: 20px;
  text-align: center;
  font-size: 11px;
  font-weight: 300;
  z-index: 5;
  position: relative;
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaAmountLeft___1FFqD {
  display: inline-block;
  vertical-align: top;
  line-height: 20px;
  margin-right: -16px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  width: 20px;
  height: 20px;
  background: #6b6867;
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaAmountRight___Wv8d7 {
  display: inline-block;
  vertical-align: top;
  line-height: 20px;
  margin-left: -16px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  width: 20px;
  height: 20px;
  background: #6b6867;
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaGroup___18ZFb .App__fileLink___1_3s2 {
  color: #333;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 12px;
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaGroup___18ZFb .App__file___3gkZp {
  cursor: pointer;
  position: relative;
  padding: 8px 0 8px 35px;
}
@media (max-width: 768px) {
  .App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaGroup___18ZFb .App__file___3gkZp {
    padding: 4px 0 4px 25px
  }
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaGroup___18ZFb .App__file___3gkZp:hover {
  background-color: #e8e5e1;
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaGroup___18ZFb .App__fileNoType___1Iuen {
  padding: 8px 0 8px 6px;
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaGroup___18ZFb .App__file_icon___2qTrx {
  position: absolute;
  top: 7px;
  left: 5px;
  width: 20px;
  height: 20px;
}
@media (max-width: 768px) {
  .App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaGroup___18ZFb .App__file_icon___2qTrx {
    width: 26px;
    height: 26px
  }
}
.App__container___po1AR .App__list___1Y_Ke .App__media____AHJy .App__mediaGroup___18ZFb .App__fileBG___3zNY3 {
  background-color: #f7f6f4;
}
