@font-face {
  font-family:'Soho Gothic Pro Light';
  src: url(SohoGothicPro-Light.eot);
  src: url(SohoGothicPro-Light.eot?#iefix) format('embedded-opentype'),
  url(SohoGothicPro-Light.woff2) format('woff2'),
  url(SohoGothicPro-Light.woff) format('woff'),
  url(SohoGothicPro-Light.svg#SohoGothicPro-Light) format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Soho Gothic Pro';
  src: url(SohoGothicPro-Regular.eot);
  src: url(SohoGothicPro-Regular.eot?#iefix) format('embedded-opentype'),
  url(SohoGothicPro-Regular.woff2) format('woff2'),
  url(SohoGothicPro-Regular.woff) format('woff'),
  url(SohoGothicPro-Regular.svg#SohoGothicPro-Regular) format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Soho Gothic Pro';
  src: url(SohoGothicPro-Italic.eot);
  src: url(SohoGothicPro-Italic.eot?#iefix) format('embedded-opentype'),
  url(SohoGothicPro-Italic.woff2) format('woff2'),
  url(SohoGothicPro-Italic.woff) format('woff'),
  url(SohoGothicPro-Italic.svg#SohoGothicPro-Italic) format('svg');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Soho Gothic Pro Medium';
  src: url(SohoGothicPro-Medium.eot);
  src: url(SohoGothicPro-Medium.eot?#iefix) format('embedded-opentype'),
  url(SohoGothicPro-Medium.woff2) format('woff2'),
  url(SohoGothicPro-Medium.woff) format('woff'),
  url(SohoGothicPro-Medium.svg#SohoGothicPro-Medium) format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Soho Gothic Pro Medium';
  src: url(SohoGothicPro-MediumItalic.eot);
  src: url(SohoGothicPro-MediumItalic.eot?#iefix) format('embedded-opentype'),
  url(SohoGothicPro-MediumItalic.woff2) format('woff2'),
  url(SohoGothicPro-MediumItalic.woff) format('woff'),
  url(SohoGothicPro-MediumItalic.svg#SohoGothicPro-MediumItalic) format('svg');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Soho Gothic Pro';
  src: url(SohoGothicPro-Bold.eot);
  src: url(SohoGothicPro-Bold.eot?#iefix) format('embedded-opentype'),
  url(SohoGothicPro-Bold.woff2) format('woff2'),
  url(SohoGothicPro-Bold.woff) format('woff'),
  url(SohoGothicPro-Bold.svg#SohoGothicPro-Bold) format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Soho Gothic Pro';
  src: url(SohoGothicPro-BoldItalic.eot);
  src: url(SohoGothicPro-BoldItalic.eot?#iefix) format('embedded-opentype'),
  url(SohoGothicPro-BoldItalic.woff2) format('woff2'),
  url(SohoGothicPro-BoldItalic.woff) format('woff'),
  url(SohoGothicPro-BoldItalic.svg#SohoGothicPro-BoldItalic) format('svg');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

.SohoGothicPro__soho-gothic-pro___1tV4R {
  font-family: 'Soho Gothic Pro', sans-serif;
}
